@media (min-width: 300px) {
    .course-tiles {
        --cols: 1
    }

    .sideactive .course-tiles {
        --cols: 1
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }

}



@media (min-width: 350px) {

    .course-tiles {
        --cols: 1
    }

    .sideactive .course-tiles {
        --cols: 2
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }
}

@media (min-width: 450px) {

    .course-tiles {
        --cols: 1
    }

    .sideactive .course-tiles {
        --cols: 2
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }
}

@media (min-width: 600px) {

    .course-tiles {
        --cols: 2
    }

    .sideactive .course-tiles {
        --cols: 3
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }
}

@media (min-width: 850px) {
    .course-tiles {
        --cols: 3
    }

    .sideactive .course-tiles {
        --cols: 4
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }

}

@media (min-width: 1000px) {

    .course-tiles {
        --cols: 4
    }

    .sideactive .course-tiles {
        --cols: 5
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }
}

@media (min-width: 1250px) {

    .course-tiles {
        --cols: 5
    }

    .sideactive .course-tiles {
        --cols: 5
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }

}

@media(min-width: 1500px) {
    .sidmenu {
        max-width: 15rem;
    }

    .main-content {
        left: 15rem;
        max-width: calc(100% - 15rem);
        min-width: calc(100% - 15rem);
    }

}

@media (min-width: 1750px) {
    .course-tiles {
        --cols: 6
    }

    .sideactive .course-tiles {
        --cols: 6
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }

    header {
        height: 68px;
    }

    .sidmenu,
    .main-content {
        top: 68px;
    }

    .sidmenu {
        max-width: 15rem;
    }

    .main-content {
        left: 15rem;
        max-width: calc(100% - 15rem);
        min-width: calc(100% - 15rem);
    }

    .user-sat-detail {
        margin-top: -13rem;
    }

    .user-sat-detail h5 {
        font-size: 1.6rem;
    }

    .user-sat-detail h6 {
        font-size: 1.3rem;
    }

    .user-sat-detail p {
        font-size: 1.2rem;
    }
}

@media (min-width: 2000px) {

    .course-tiles {
        --cols: 6
    }

    .sideactive .course-tiles {
        --cols: 7
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }
}

@media (min-width: 2300px) {

    .course-tiles {
        --cols: 7
    }

    .sideactive .course-tiles {
        --cols: 8
    }

    .course-tiles .tilecard {
        max-width: 100%;
    }
}


@media(max-width: 1500px) {
    .sidmenu {
        max-width: 13rem;
    }

    .main-content {
        left: 13rem;
        max-width: calc(100% - 13rem);
        min-width: calc(100% - 13rem);
    }

    .mainsidelist ul li p {
        font-size: 0.8rem;
    }

    .updated.toptab .nav-item .nav-link {
        font-size: 0.8rem;
    }

    .group-user-name {
        font-size: 1rem;
    }

    .initials-placeholder {
        height: 4rem;
        width: 4rem;
    }

    .avatarimg {
        max-width: 4rem;
        max-height: 4rem;
        height: 4rem;
        width: 4rem;
    }

    .dashstatdata .welcometext {
        padding-left: 15px;
    }

    .main-dashboard p {
        font-size: 0.85rem;
    }

    .welcometext h4 {
        font-size: 1.15rem;
        font-weight: 600;
    }

    .datacardesign.invoice span p {
        font-size: 0.9rem;
    }

    .datacardesign.invoice span h5,
    .datacardesign.invoice .small-detail span h5 {
        font-size: 1.25rem;
        font-weight: 600;
    }

    .datacardesign {
        min-height: 6rem;
    }

    .user-sat-detail {
        margin-top: -8.5rem;
    }

    .groupcardesign .groupname,
    .usercardesign .groupname {
        font-size: 0.8rem;
    }

    .groupcardesign p,
    .usergrouplist .groupcardesign p {
        font-size: 0.75rem;
    }
}

@media(max-width: 1650px) {

    .mainlogo p,
    .profileavatar p:first-child {
        font-size: 0.9rem;
    }

    .mainsidelist ul li p,
    .updated.toptab .nav-item .nav-link {
        font-size: 0.85rem;
    }

    .mainsidelist ul li svg {
        width: 15px;
        height: 15px;
    }

    .rangetype p {
        font-size: 0.9rem !important;
    }

    .avatarimg {
        max-height: 4rem;
        width: 4rem;
        height: 4rem;
        max-width: 4rem;
    }

    .group-user-name {
        font-size: 0.95rem;
    }

    .main-dashboard p,
    .datacardesign.invoice .small-detail span p {
        font-size: 0.85rem;
    }

    .datacardesign.invoice span.EnlargeIcon,
    .chart-info span.EnlargeIcon {
        width: 1.2rem;
        height: 1.2rem;
    }

    .datacardesign.invoice .small-detail span h5 {
        font-size: 1.1rem
    }

    .datacardesign {
        min-height: 6rem;
    }

    .chart-info span {
        font-size: 0.85rem;
    }

    .app-search .form-control {
        height: 38px;
        font-size: 0.85rem;
    }

    .classic-btn.for-filter,
    .classic-btn {
        height: 38px;
        font-size: 0.9rem;
    }

    .csvuploaddownload .uloadoanload, .DefaultRedBtn.download {
        height: 38px;
    }

    .csvuploaddownload .uloadoanload svg {
        width: 0.9em;
        height: 0.9em;
        padding: 0;
    }

    .csvuploaddownload .uloadoanload span {
        padding: 2px 0;
    }

    .classic-btn.for-filter svg {
        width: 18px;
    }

    .page-tile h5 {
        font-size: 1rem;
    }

    .cate-table {
        max-height: 70vh;
    }

    .page-tile h5 .backbtn {
        font-size: 0.8rem;
    }

    .bomlist .cate-table th,
    .bomlist .cate-table td {
        font-size: 0.7rem;
    }

    .total-lane,
    .details-here {
        font-size: 0.95rem;
        padding: 0.5rem 0.7rem;
    }

    .details-here p {
        font-size: 0.95rem;
    }

    .cate-table th {
        padding: 0.7rem 0.25rem;
    }

    /* .cate-table td {
        padding: 0.65rem 0;
    } */

    .delete-btn {
        font-size: 0.85rem;
        height: 30px;
    }
}


@media(max-width: 1500px) {

    .mainlogo p,
    .profileavatar p:first-child {
        font-size: 0.85rem;
    }

    .mainsidelist ul li p,
    .updated.toptab .nav-item .nav-link {
        font-size: 0.8rem;
    }

    .mainsidelist ul li svg {
        width: 14px;
        height: 14px;
    }

    .rangetype p {
        font-size: 0.85rem !important;
    }

    .DefaultRedBtn.download {
        height: 35px;
        width: 40px;
    }

    .avatarimg {
        max-height: 3.5rem;
        width: 3.5rem;
        height: 3.5rem;
        max-width: 3.5rem;
    }

    .group-user-name {
        font-size: 0.9rem;
    }

    .main-dashboard p,
    .datacardesign.invoice .small-detail span p {
        font-size: 0.8rem;
    }

    .datacardesign.invoice span.EnlargeIcon,
    .chart-info span.EnlargeIcon {
        width: 1.1rem;
        height: 1.1rem;
    }

    .datacardesign.invoice .small-detail span h5 {
        font-size: 1rem
    }

    .datacardesign {
        min-height: 5rem;
    }

    .chart-info span {
        font-size: 0.85rem;
    }

    .app-search .form-control {
        height: 35px;
        font-size: 0.85rem;
    }

    .classic-btn.for-filter,
    .classic-btn {
        height: 35px;
        font-size: 0.8rem;
    }

    .csvuploaddownload .uloadoanload {
        height: 35px;
        padding: 0;
    }

    .csvuploaddownload .uloadoanload svg {
        width: 0.8em;
        height: 0.8em;
        padding: 0;
    }

    .csvuploaddownload .uloadoanload span {
        padding: 2px 0;
        padding-bottom: 1px;
    }

    .classic-btn.for-filter svg {
        width: 18px;
    }

    .page-tile h5 {
        font-size: 0.9rem;
    }

    .cate-table {
        max-height: 65vh;
    }

    .page-tile h5 .backbtn {
        font-size: 0.75rem;
    }

    .bomlist .cate-table th,
    .bomlist .cate-table td {
        font-size: 0.75rem;
    }

    .total-lane,
    .details-here {
        font-size: 0.85rem;
    }

    .details-here p {
        font-size: 0.85rem;
    }

    .cate-table th,
    .cate-table td,
    .des p,
    .active-label,
    .inactive-label {
        font-size: 0.8rem;
    }

    .css-1m26d3y-MuiSwitch-root .MuiSwitch-thumb {
        height: 15px !important;
        width: 15px !important;
    }

    .css-1m26d3y-MuiSwitch-root {
        width: 35px !important;
        height: 19px !important;
    }

    .cate-table td img,
    .cate-table td .img-ph {
        height: 3.5rem;
        font-size: 1.6rem;
    }

    table .action-btn span svg {
        width: 1.05rem;
        height: 1.05rem;
    }

    table .action-btn span {
        padding: 0.2rem;
    }

    .css-y0vtut-MuiPagination-root .MuiPaginationItem-root {
        font-size: 0.8rem !important;
        min-width: 28px;
        height: 28px;
    }
    .current-stat{
        font-size: 0.8rem;
    }
    .filterbox .filterhead p{
        font-size: 1rem;
    }
    .filterhead button{
        font-size: 0.8rem;
    }
    .filterbox .createrhead p,.filterbox .createdatahere label span{
        font-size: 0.85rem;
    }
    .filterbox .createrhead p svg{
        width: 0.9rem;
        height: 0.9rem;
    }
    .filterbox .createdfilter svg{
        height: 17px;
        width: 17px;
    }
    .filteroption span {
        font-size: .8rem;
        padding: 7px 10px;
    }
    .filteroption span svg {
        height: 1rem;
        width: 1rem;
    }
}


/* .bomlistp .cate-table th{
    background-color: #f2f2f2;
} */
.bomlistp .cate-table th, .bomlistp .cate-table td{
    padding: .5rem;
    border-color: #c2c2c2;
    border-width: 1px;
}
.bomlistp .cate-table thead{
    top: -1px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

input,
textarea {
  font-family: "Poppins", sans-serif !important;
}

body.filter-active {
  overflow: hidden;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
}

*::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f3f3f3;
  display: block;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.menu-long::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #55555550;
}

body {
  padding: 0 !important;
  margin: 0 !important;
}

/* HEADER CSS */

.sidetoggle,
header {
  align-items: center;
  display: flex;
}

body::-webkit-scrollbar {
  display: none;
}

header {
  align-items: center;
  display: flex;
  background: #fff;
  border-bottom: 2px solid #e3e2e2 !important;
  height: 58px;
  justify-content: space-between;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.sidetoggle {
  gap: 20px;
  padding-left: 10px;
}

p.error {
  margin: 0;
  color: #ca373c;
  font-weight: 500;
  font-size: 0.8rem;
  min-height: 17px;
}

.sidesvgdiv {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sidetoggle svg {
  fill: #000;
  cursor: pointer;
  height: 16px;
  width: 18px;
}

.sidetoggle .activebar #Path_3 {
  -webkit-transform: translate(-10px, -4.523px);
  transform: translate(-10px, -4.523px);
  transition: all 0.3s ease;
}

.sidetoggle #Path_3 {
  transition: all 0.3s ease;
}

.mainlogo {
  align-items: center;
  display: flex;
  justify-content: center;
}

.mainlogo img {
  height: 40px !important;
  object-fit: contain;
  width: 40px !important;
}

.mainlogo p {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding-left: 1rem;
  line-height: 2rem;
}

.leftmain {
  gap: 20px;
}

.leftmain,
.profile {
  align-items: center;
  display: flex;
  justify-content: start;
}

.profile {
  gap: 5px;
}

.profileavatar {
  align-items: center;
  display: flex;
  gap: 3px;
}

.profilemenudata p {
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

.profileavatar p:first-child {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}

.profilepik {
  border: none;
  box-shadow: 0 3px 6px #00000029;
  background-color: #505050;
  border-radius: 50%;
  height: 30px;
  padding: 1px;
  width: 30px;
}

.profileavatar {
  align-items: center;
  display: flex;
  gap: 3px;
}

.profilemenuhead {
  align-items: center;
  border-bottom: 2px solid #70707030;
  display: flex;
  gap: 15px;
  min-width: 300px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 10px;
}

.appnavigator .themetoggle {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.themetoggle {
  background-color: initial !important;
  border: none !important;
  display: flex !important;
}

.menu-long {
  max-height: 21rem;
  overflow: auto;
  padding: 10px 7px 10px 10px;
}

.appnavigator .themetoggle svg {
  height: 20px;
  width: 20px;
}

.appshere {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  width: 90px;
}

.appshere:hover {
  background-color: #f3f3f3;
}

.appshere img {
  height: 30px;
  object-fit: contain;
  width: 30px;
}

.appshere p {
  color: #333 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  margin: 0 !important;
  min-height: 30px;
  text-align: center !important;
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  content: inherit !important;
}

p.logoutbtn {
  color: #ca373c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 8px;
  text-align: center;
  width: 100%;
}

.appnavigator .dropdown-menu.show {
  background-color: #f3f3f3;
  padding: 0px !important;
  min-width: auto;
  overflow: hidden;
}

.appnavigator .dropdown-menu.show .menubg {
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: max-content;
}

.appnavigator .dropdown-menu.show .menubg.extraspace {
  max-width: 320px;
}

.profilepik {
  border: none;
  box-shadow: 0 3px 6px #00000029;
  background-color: #b7b7b7;
  border-radius: 50%;
  height: 35px;
  padding: 2px;
  width: 35px;
}

.avatar-placeholder {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  background-color: #505050;
  padding: 1px;
  margin-left: 5px;
  -o-object-fit: contain;
  object-fit: contain;
  color: #fff;
}

/* *********** */
/* SIDEBAR CSS */
/* *********** */

.sidmenu {
  background-color: #fff;
  border-right: 2px solid #e3e2e2 !important;
  max-width: 14rem;
  min-height: calc(100% - 58px);
  overflow-x: hidden;
  position: fixed;
  top: 58px;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 1rem; */
}

.mainsidelist {
  padding: 1rem 0 1rem;
}

.mainsidelist ul {
  list-style-type: none;
  padding: 0;
}

.mainsidelist ul li {
  align-items: center !important;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: flex !important;
  gap: 0;
  justify-content: start !important;
  margin: 4px 0;
  padding: 5px 0 5px 10px;
  position: relative;
  width: 96%;
}

.mainsidelist ul li.active {
  background-color: #ca373c;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff !important;
}

.mainsidelist ul li svg {
  fill: #000;
  height: 18px;
  margin-left: 15px;
  width: 18px;
}

.mainsidelist ul li.active svg {
  fill: #fff;
}

.mainsidelist ul li p {
  text-wrap: nowrap;
  color: #333 !important;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.mainsidelist ul li.active p {
  color: #fff !important;
}

.mainsidelist ul li svg path {
  fill: #000;
}

.mainsidelist ul li.active svg path {
  fill: #fff;
}

.sidmenu.active {
  left: -10px;
  max-width: 0;
  min-height: calc(100% - 63px);
  min-width: 0;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.mainsidelist ul li:hover {
  background-color: #ca373c50;
}

p.pwrdby {
  color: #4b4b4b;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 0.5rem;
  padding-left: 5%;
  padding-top: 0.5rem;
  text-align: left;
  width: 100%;
}

p.pwrdby,
p.pwrdby strong {
  align-items: center;
  display: flex;
  justify-content: start;
}

p.pwrdby strong {
  font-weight: 600;
  gap: 5px;
}

p.pwrdby strong img {
  filter: grayscale(4);
  margin-left: 0.3rem;
  opacity: 0.95;
  width: 1rem;
}

/* *********** */
/* MAINBODY CSS */
/* *********** */

.main-content {
  background-color: initial;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
  left: 14rem;
  max-width: calc(100% - 14rem);
  min-height: calc(100vh - 58px);
  min-width: calc(100% - 14rem);
  padding: 0;
  position: relative;
  top: 58px;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  background-color: #f3f3f3;
}

.main-content.sideactive {
  left: 0;
  min-width: 100%;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.main-content.editorhere {
  left: 0;
}

.page-tile {
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.35rem;
  min-height: 40px;
}

.page-tile h5 {
  font-size: 1.1rem;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: start;
}

.page-tile h5 .backbtn {
  font-size: 0.9rem;
  gap: 5px;
  padding: 0.25rem 0.5rem;
}

.searchnadd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.app-search svg {
  color: #a4a3a3;
  left: 25px;
}

.app-search .searchiconcancel svg,
.app-search svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.app-search .form-control {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #e3e2e2;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  height: 42px;
  padding-left: 45px;
  padding-right: 20px;
  width: 300px;
}

.app-search .form-control:focus {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #70707080;
  box-shadow: none;
  color: #333;
}

.classic-btn {
  align-items: center;
  background: #ca373c;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 0.95rem;
  gap: 5px;
  justify-content: center;
  padding: 0 1rem;
  height: 42px;
  width: max-content;
  border: none;
}

.classic-btn svg {
  color: #fff;
  cursor: pointer;
  margin-top: -2px;
  padding-right: 5px;
  width: 20px;
}

.category-main,
.product-main {
  padding: 1rem;
}

.cate-table {
  margin-top: 0rem;
}

.cate-table th {
  font-size: 0.9rem;
  font-weight: 600;
  color: #474747;
  padding: 1rem 0.3rem;
  background-color: #dddddd;
  align-content: center;
}

.cate-table tbody {
  background-color: #fff;
}

.cate-table td {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  /* padding: 0.8rem 0; */
  max-width: 15rem;
  align-content: center;
  word-wrap: break-word;
}

.cate-table td img {
  aspect-ratio: 16 / 9;
  height: 4rem;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: #00000090 0px 0px 4px;
}

.cate-table td .img-ph {
  aspect-ratio: 16 / 9;
  height: 4rem;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: #00000090 0px 0px 4px;
  background-color: #33333350;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 2rem;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.cate-table td .table-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
}

table .active {
  background-color: rgb(0, 218, 80);
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  text-transform: capitalize;
}

table .inactive {
  background-color: #ff3636;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  text-transform: capitalize;
}

table .action-btn span {
  background-color: #ca373c20;
  padding: 0.3rem;
  border-radius: 5px;
  cursor: pointer;
}

table .action-btn span:hover {
  background-color: #ca373c50;
}

table .action-btn span svg {
  width: 1.2rem;
  height: 1.2rem;
}

table .action-btn.p p {
  color: #ca373c;
  margin: 0;
  font-size: 0.9rem;
  cursor: pointer;
}

table .action-btn.p p:hover {
  text-decoration: underline;
}

.cate-table {
  margin-top: 0rem;
  min-height: 55vh;
  max-height: 75vh;
  background-color: #fff;
}

.cate-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #dddddd;
}

.MuiSkeleton-root {
  z-index: 0;
}

.des p {
  margin: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.breadcrumb p {
  margin: 0;
  color: #333;
  font-weight: 400;
  font-size: 0.95rem;
  cursor: pointer;
}

.breadcrumb p.active {
  font-weight: 500;
  /* text-decoration: underline; */
  color: #ca373c;
}

.breadcrumb p:hover {
  text-decoration: underline;
}

.breadcrumb span {
  margin: 0 0.5rem 0 0.5rem;
  color: #4b5563;
  margin-top: -2px;
}

.breadcrumb span svg {
  width: 14px;
  height: 14px;
}

.mainsidelist ul li.with-child {
  flex-direction: column;
  justify-content: start !important;
  align-items: start !important;
}

.main-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.child-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
  margin-top: 0.3rem;
}

.child-list p {
  width: 100%;
  background-color: #474747;
  padding: 5px 0 5px 10px;
  padding-left: 3rem;
}

.modalheader {
  align-items: center;
  background-color: #ca373c;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.modalheader span {
  cursor: pointer;
}

.modalheader span svg {
  height: 1.7rem;
  width: 1.7rem;
}

.modal-footer-btn,
.profile-Logo {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.profile-Logo {
  flex-direction: column;
  justify-content: center;
}

.dropzone {
  background-color: #f8f8f8;
  border: 2px dashed #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 5.5rem;
  line-height: 5.5rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 5.5rem;
}

.profile-Logo p {
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

.profile-Logo p span {
  color: #ca373c;
}

.groupEditLogo {
  background-color: #d1d1d150;
  border: 2px solid #d1d1d1;
  border-radius: 50%;
  height: 5.5rem;
  max-height: 5.5rem;
  max-width: 5.5rem;
  overflow: hidden;
  width: 5.5rem;
}

.groupEditLogo img {
  height: 100%;
  object-fit: cover;
  padding: 0;
  width: 100%;
}

.groupEditLogo img {
  object-fit: contain;
}

.closeicon {
  align-items: center;
  background: #ca373c9e;
  bottom: -25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 50%;
  padding: 5px 0 11px;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10rem;
}

.closeicon svg {
  fill: #fff;
  height: 1.2rem;
  object-fit: contain;
  width: 1.2rem;
}

.dropzonimg {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  left: 50%;
  opacity: 0.05;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
}

.createform {
  margin: 1rem 0;
  max-height: 55vh;
  overflow-y: auto;
  padding: 0 0.5rem;
}

.form-group,
.select-type {
  margin-bottom: 0.5rem;
}

.createform p,
.form-group p,
.form-group.des p,
.select-option-radio p,
.select-type p,
.popupselect p {
  color: #333;
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.createform p span,
.form-group p span,
.select-type p span {
  color: #ca373c;
}

/* .createform fieldset {
    border: none ;
} */

.form-group input {
  border: 1.5px solid #d3d3d3;
  border-radius: 5px;
  color: #333;
  font-size: 1rem;
  height: 18px;
  padding: 10px 15px 10px 10px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.form-group.des fieldset {
  border: 1.5px solid #d3d3d3 !important;
  border-radius: 5px;
  color: #333;
  font-size: 1rem;
  height: auto;
  padding: 10px 15px 10px 10px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.css-muvvuh:focus {
  outline: 0px !important;
}

.form-group fieldset {
  outline: none;
  border: none;
}

.form-group input:focus,
.form-group input:focus-visible,
.form-group input:focus-within {
  background: #f8f8fa 0 0 no-repeat padding-box;
  border: 1.5px solid #70707090;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.form-group .des fieldset:focus,
.form-group .des fieldset:focus-visible,
.form-group .des fieldset:focus-within {
  background: #f8f8fa 0 0 no-repeat padding-box;
  border: 1.5px solid #70707090;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.css-1p8eru7-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  /* background: #f8f8fa 0 0 no-repeat padding-box; */
  border: 1.5px solid #70707090 !important;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  outline: none;
}

.selectall-option {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  justify-content: start;
}

.selectall-option p {
  color: #333;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

.active-label,
.inactive-label {
  font-size: 0.85rem;
  font-weight: 500;
  min-width: 3.5rem;
}

.active-label {
  color: green;
}

.inactive-label {
  color: gray;
}

.modal-footer-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.createbtn {
  background-color: #ca373c;
  color: #fff;
  padding: 0 1rem;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ca373c;
  transition: 0.3s;
  letter-spacing: 1px;
  height: 40px;
  font-size: 0.8rem;
  min-width: 6rem;
  width: -moz-max-content;
  width: max-content;
}

.createbtn:hover {
  border: 1px solid #d8383f;
  background-color: #d8383f;
  color: #fff;
}

.createbtn.cancelbtn {
  background-color: transparent;
  color: #333333;
  font-weight: 500;
  border: none;
  border: 1px solid rgba(51, 51, 51, 0.3137254902);
}

.createbtn.cancelbtn:hover {
  background-color: rgba(51, 51, 51, 0.3137254902);
  color: #fff;
}

button.save.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: not-allowed;
  width: 6rem;
}

button.save.loading svg {
  width: 2em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

button.save.loading circle {
  fill: none;
  stroke: #fff;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

button.save {
  background-color: #ca373c;
  color: #fff;
  padding: 0 1rem;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ca373c;
  transition: 0.3s;
  letter-spacing: 1px;
  height: 40px;
  font-size: 0.8rem;
  min-width: 6rem;
}

.classic-btn.for-filter {
}

.classic-btn.for-filter {
  align-items: center;
  background: #ca373c;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 0.95rem;
  gap: 5px;
  justify-content: center;
  height: 42px;
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  /* height: 38px; */
}

.classic-btn.for-filter svg {
  color: #fff;
  cursor: pointer;
  margin-top: -2px;
  padding-right: 5px;
  width: 20px;
}

.classic-btn.for-filter .numberhere {
  width: 26px;
  height: 26px;
  background-color: #333;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
}

.app-search .searchiconcancel svg {
  color: #00000080;
  cursor: pointer;
  height: 1.3em;
  left: auto;
  right: -5px;
  width: 1.3em;
}

/* filters */

.filterbox.inactive {
  right: -450px;
  transition: 0.3s all ease;
}

.filterbox {
  background: #faebd7;
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  height: auto;
  padding: 10px;
  position: fixed;
  right: 0;
  top: 0;
  max-width: 450px;
  width: 30vw;
  z-index: 10;
  height: 100vh;
  transition: 0.3s all ease;
}

.filterbox .filterhead {
  display: flex;
  justify-content: space-between;
}

.filterbox .filterhead p {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.filterbox .filterhead p:first-child {
  border-bottom: 2px solid #333;
  color: #333;
  font-weight: 500;
}

.filterhead button {
  background-color: initial;
  border: none;
  border-radius: 5px;
  color: #ca373c;
  font-size: 16px;
  padding: 2px 5px;
}

.filterbox .createdfilter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: start;
  margin-top: 10px;
  overflow: hidden;
  padding-bottom: 10px;
  transition: all 0.4s ease-in-out;
}

.filterbox .createdfilter.createdactive {
  height: 225px;
  max-height: 230px;
  transition: all 0.4s ease-in-out;
}

.filterbox .createrhead {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.filterbox .createrhead p {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.filterbox .createdfilter svg {
  fill: #ca373c;
  height: 20px;
  width: 20px;
}

.filterbox .createrhead p svg {
  fill: #ca373c;
  width: 1em;
  height: 1em;
}

.showoverlay {
  background-color: #00000070;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.filterbox .createdatahere {
  /* max-height: 170px; */
  overflow-y: auto;
  padding: 0;
}

.filterbox .createdatahere::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
}

.filterbox .createdatahere::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f3f3f3;
  display: block;
}

.filterbox .createdatahere::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.filterbox .search input {
  height: 40px;
}

.filterbox .createdatahere label span {
  font-size: 13px;
  padding: 2px 0 2px 15px;
}

.filterbox .createdatahere label span {
  font-size: 15px;
  font-weight: 400;
  padding: 2px 0 2px 15px;
  font-family: "Poppins", sans-serif;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterbox .searchfields .searchicon svg g {
  opacity: 1;
}

.filterbox .searchfields .searchicon svg path {
  fill: #ca373c;
}

.filterbox .createdatahere label span:nth-child(2) {
  padding-left: 5px;
}

.filteroption span.numberhere {
  width: 26px;
  height: 26px;
  background-color: #333;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
}

.count {
  width: 20px;
  height: 20px;
  background-color: #ca373c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 50%;
}

.searchfields .searchicon {
  position: absolute;
  top: 7px;
  left: 10px;
}

.searchfields .searchicon svg {
  width: 17px;
  height: 17px;
}

.searchfields .searchiconcancel {
  position: absolute;
  top: 7px;
  right: 10px;
}

.searchfields .searchiconcancel svg {
  width: 17px;
  height: 17px;
}

.searchfields .form-control {
  padding-left: 36px;
  height: 40px;
  font-size: 0.9rem;
}

.searchfields .form-control:focus {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}

.searchfields .form-control:focus-visible {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}

.searchfields .form-control:focus-within {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}

.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(55vh - 55px);
  border-radius: 10px;
}

.no-data-found p {
  font-weight: 500;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.current-stat {
  font-weight: 400;
  color: #575757;
  font-size: 0.9rem;
}

.breadcrumb .dropdown button {
  background-color: transparent !important;
  padding: 0;
  margin: 0;
  border: none;
}

.breadcrumb .dropdown button:hover,
.dropdown button:focus,
.dropdown button:focus-visible,
.dropdown button:focus-within {
  background-color: transparent !important;
}

.breadcrumb .dropdown-menu {
  border: none;
}

.breadcrumb .dropdown-menu a {
  margin: 0;
  color: #333;
  font-weight: 400;
  font-size: 0.9rem;
  cursor: pointer;
  background-color: #fff;
}

.breadcrumb .dropdown-menu a:hover {
  background-color: #00000020;
}

.link-here {
  cursor: pointer;
  color: #0044cc;
}

.link-here:hover {
  text-decoration: underline;
}

.css-1gaup4j-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  background-color: #ca373c !important;
}

p.Mui-error,
p.errormessage,
p.successmessage {
  font-size: 0.7rem !important;
  margin: 0 !important;
  padding: 0 !important;
}

p.Mui-error,
p.errormessage {
  color: #ff4842 !important;
}

.add-product {
  background-color: #fff;
  min-height: calc(100vh - 58px);
}

.add-product .nav.nav-tabs {
  background: #fff;
  border-color: #e3e2e2;
  padding-left: 15px;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.add-product .nav-link {
  border-left: none;
  border-right: none;
  border-top: none;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  opacity: 50%;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}

.add-product .nav-link.active {
  background: none;
  border-color: #ca373c;
  color: #333;
  font-weight: 500;
  opacity: 1;
}

.add-product .nav-link.active:after {
  background-color: #ca373c;
  bottom: -4px;
  -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  content: "";
  height: 7px;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 11px;
}

.basic-details,
.assets-page {
  padding-top: 3.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.css-1p8eru7-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.p-treeselect-panel,
.p-component {
  font-size: 0.9rem;
}

.p-treeselect-panel ul {
  padding: 0;
  margin: 0;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 0.5rem;
}

.p-treeselect:hover {
  border: 1px solid #d1d5db;
}

.p-treeselect:not(.p-disabled).p-focus {
  background: #f8f8fa 0 0 no-repeat padding-box;
  border: 1.5px solid #70707090;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: none;
}

.p-treeselect .p-treeselect-label {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  font-size: 0.9rem;
}

.p-treeselect .p-treeselect-trigger svg {
  width: 0.9rem;
  height: 0.9rem;
  opacity: 0.5;
}

.p-tree-toggler svg {
  width: 0.9rem;
  height: 0.9rem;
}

.p-highlight .p-tree-toggler svg path {
  fill: #ca373c;
}

.p-treeselect-filter-container input {
  height: 38px;
  font-size: 0.85rem;
}

.p-treeselect-filter-container input:hover {
  border: 1px solid #d1d5db;
}

.p-treeselect-filter-container input:focus,
.p-treeselect-filter-container input:focus-visible,
.p-treeselect-filter-container input:focus-within {
  background-color: #f8f8fa 0 0 no-repeat padding-box;
  border: 1.5px solid #70707090;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: none;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: rgba(0, 0, 0, 43%);
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  width: 0.95rem;
}

.p-treeselect-close {
  width: 1.3rem;
  height: 1.3rem;
}

.p-treeselect-close svg {
  width: 1rem;
  height: 1rem;
}

.p-treeselect-panel .p-treeselect-header {
  padding: 0.5rem 1rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 1rem;
}

.p-tree {
  padding: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 6px;
  transition: box-shadow 0.2s;
  padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: #ca373c;
  background-color: #ca373c20;
  box-shadow: none;
}

.product-add .dropzone,
.product-add .groupEditLogo {
  width: 100%;
  border-radius: 5px;
  height: 10rem;
  max-width: 100%;
  max-height: 100%;
}

.product-add .closeicon {
  width: 100%;
  bottom: -20px;
  left: 50%;
  padding: 6px 0 10px;
}

.img-upload-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.img-upload-bg p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
  opacity: 0.5;
  line-height: 1.5rem;
}

.img-upload-bg p:last-child {
  font-size: 1.2rem;
  font-weight: 700;
}

.tab-back-icon svg {
  transform: rotateY(180deg);
  margin-right: 5px;
  opacity: 0.6;
}

.popupselect .radio-inputs {
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  gap: 10px;
  padding: 0.25rem;
  position: relative;
  width: 100%;
}

.css-p58oka {
  display: flex;
  flex-flow: wrap;
}

.asset-label span {
  border-radius: 5px;
  border: 1px solid #ca373c;
  background-color: #cacaca20;
  color: #ca373c;
  font-size: 0.9rem;
  padding: 0.35rem 0.75rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.asset-label span.Mui-checked + span {
  color: #fff;
  background-color: #ca373c;
}

.asset-label span:first-child {
  display: none !important;
}

.uploadfilesss .fileuploader {
  height: 8rem;
  position: relative;
}

.fileuploader > label {
  -webkit-box-flex: 0;
  align-items: center;
  border: 2px dashed #33333330;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 8rem;
  height: 100%;
  justify-content: center;
  max-width: 800px;
  min-width: 100%;
  padding: 8px 16px 8px 8px;
  position: relative;
}

span.upload-box-info {
  font-size: 0.85rem;
  text-align: center;
}

.uploadstatus {
  margin-top: 20px;
}

.uploaddata {
  background: #f0f3f5 0 0 no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.name-cancel,
.file-size {
  display: flex;
  justify-content: space-between;
}

.uploaddata p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upperdiv .filesize {
  color: #2a2a2a;
  font-size: 12px;
}

.name-cancel span {
  width: 1.2rem;
  height: 1.2rem;
  background-color: #ca373c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 50%;
}

.name-cancel span svg {
  fill: #fff;
  width: 1rem;
  height: 1rem;
}

.progressbar-container {
  background-color: #fff;
  border-radius: 3px !important;
  margin-top: 5px;
}

.progressbar-progress {
  background-color: #ca373c !important;
  border-radius: 3px !important;
  height: 7px !important;
}

.basicDetailError {
  color: #ca373c;
  font-size: 0.8rem;
  font-weight: 500;
}

.deploy-card {
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 100%;
}

.deploy-card p {
  font-size: 1rem;
  color: #474747;
  font-weight: 500;
}

.filedetails p {
  color: #ca373c;
  font-size: 1rem;
  font-weight: 500;
  margin: 15px 0;
}

.filedetails p span {
  color: #333;
  padding-left: 15px;
}

.deploy-comment {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  width: 100%;
}

.updated.toptab {
  display: inline-flex;
  z-index: 8;
  background: #fff;
  border-color: #e3e2e2;
  border-bottom: 1px solid #ccc;
  position: fixed;
  width: 100%;
}

.updated.toptab .nav-item {
  align-items: end;
  display: flex;
  margin-left: 15px;
}

.updated.toptab .nav-item .nav-link {
  align-items: center;
  border: none;
  border-bottom: 1px solid transparent;
  color: #333;
  display: flex;
  font-weight: 400;
  gap: 0.2rem;
  justify-content: center;
  letter-spacing: 0.3px;
  margin: 0;
  margin-top: 10px;
  padding: 5px 15px 3px;
  font-size: 0.95rem;
}

.updated.toptab .nav-item .nav-link:hover {
  border: none;
  border-bottom: 1px solid #ca373c;
  background-color: rgba(202, 55, 60, 0.063);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.updated.toptab .nav-item .nav-link.active {
  background-color: #ca373c;
  border-bottom: 1px solid transparent;
  color: #fff;
}

.backbtn svg {
  fill: #333;
  height: 0.9em;
  width: 0.9em;
}

.backbtn {
  background-color: #33333310;
  border: none;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-size: 0.8rem;
  gap: 5px;
  padding-right: 0.5rem;
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
}

.rangeselect {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: end;
  padding-right: 10px;
  width: 100%;
}

.rangetype p {
  color: #8d8d8d;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  padding: 0 2px;
}

.rangetype.active p {
  border-bottom: 2px solid #ca373c;
  color: #505050;
}

.DefaultRedBtn {
  align-items: center;
  background: #ca373c;
  border: 1px solid #ca373c;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 0.9rem;
  font-weight: 400;
  gap: 10px;
  height: 35px;
  justify-content: center;
  padding: 7px 15px;
}

.DefaultRedBtn.download {
  height: 40px;
  margin-top: -1px;
  padding: 0;
  width: 40px;
}

.datacardesign {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  min-height: 7rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
}

.cardimages {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  gap: 1rem;
}

.avatarimg {
  background: rgba(223, 225, 235, 0.4);
  border-radius: 50%;
  max-height: 5rem;
  width: 5rem;
  height: 5rem;
  max-width: 5rem;
  outline: 1px solid rgba(44, 44, 44, 0.314) !important;
  outline-offset: 0;
  overflow: hidden;
  padding: 5px;
}

.avatarimg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.courses-parent {
  margin-top: 10px;
  max-height: calc(100vh - 170px);
  min-height: calc(100vh - 170px);
  overflow-y: scroll;
  padding: 0 10px;
}

.initials-placeholder {
  text-transform: uppercase;
  background: rgba(223, 225, 235, 0.4);
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  outline: 1px solid rgba(44, 44, 44, 0.314) !important;
  outline-offset: 0;
  overflow: hidden;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
}

.group-user-name {
  font-size: 1.2rem;
}

.welcometext {
  margin-left: 11rem;
}

.welcometext p {
  color: #000;
  font-weight: 500;
  margin-bottom: 0;
}

.main-dashboard p {
  font-size: 1.1rem;
}

.datacardesign .cardimages.home img {
  bottom: 0;
  position: absolute;
  width: 12rem;
}

.dashstatdata {
  display: flex;
  gap: 2rem;
  justify-content: end;
  padding-right: 2rem;
  height: 100%;
}

.dashstatdata .welcometext {
  border-left: 2px solid #77777750;
  /* margin: 10px 0 0; */
  padding-left: 30px;
}

.dashstatdata .welcometext {
  margin-left: 0 !important;
}

.dashstatdata .welcometext p {
  color: #777777;
}

.dashstatdata .welcometext h4 {
  /* padding-top: 0.4rem; */
  padding-left: 0.4rem;
  margin: 0;
}

.welcometext h4 {
  font-size: 1.3rem;
  padding-top: 0.35rem;
  font-weight: 600;
}

.dashstatdata .welcometext {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  /* gap: 0.5rem; */
}

.dashstatdata .welcometext svg {
  margin-right: 0.2rem;
  width: 1.3rem;
  height: 1.3rem;
}

.datacardesign.invoice {
  flex-direction: column;
  color: #333;
  justify-content: space-around;
  padding: 0.5rem 1.5rem;
  height: 100%;
  padding-right: 0;
}

.datacardesign.invoice span {
  width: 100%;
  display: flex;
  align-items: start;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
  padding: 8px 0.5rem;
}

.datacardesign.invoice span p {
  font-size: 1.1rem;
  font-weight: 500;
  color: #777777;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.datacardesign.invoice span.EnlargeIcon {
  width: 1.4rem;
  height: 1.4rem;
  padding: 0;
  justify-content: end;
  align-items: end;
}

span.EnlargeIcon:hover svg {
  background-color: #00000010;
  cursor: pointer;
}

span.EnlargeIcon svg {
  width: 100%;
  height: 100%;
}

.datacardesign.invoice span.EnlargeIcon svg path {
  fill: #333;
}

.datacardesign.invoice span h5 {
  font-size: 1.4rem;
  margin: 0;
  font-weight: 600;
}

.datacardesign.invoice .small-detail {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

/* .datacardesign.invoice .small-detail span{
  width: max-content;
} */

.datacardesign.invoice .small-detail span p {
  padding-right: 0.7rem;
  color: #777777;
  font-weight: 500;
  font-size: 1.1rem;
}

.datacardesign.invoice .small-detail span h5 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
}

.chart-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}

.chart-box p {
  font-weight: 600;
  margin: 0;
  padding-top: 5px;
  text-align: start;
  color: #333;
}

.chart-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-info span {
  color: #777777;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  margin-right: 0.2rem;
}

.chart-info span.EnlargeIcon {
  width: 1.4rem;
  height: 1.4rem;
}

.chart-info span.EnlargeIcon svg path {
  fill: #333;
}

.chart-info span strong {
  font-weight: 700;
  color: #333;
}

.user-sat {
  padding-top: 2rem;
}

.user-sat-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -10rem;
}

.user-sat-detail p {
  color: #ca373c;
  font-weight: 500;
  text-align: center;
  font-size: 0.8rem;
}

.filerlist {
  align-items: center;
  border-top: 1px solid #33333315;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
  margin-top: 0;
  padding: 13px 10px 10px;
}

.filerlist span {
  font-size: 0.85rem;
  font-weight: 500;
}

.filerlist span.filtertag {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ca373c50;
  border-radius: 4px;
  display: flex;
  font-size: 0.8rem;
  font-weight: 400;
  gap: 10px;
  justify-content: center;
  padding: 2px 10px;
}

.filerlist span.filtertag.clear {
  align-items: center;
  background-color: #ca373c;
  border: 1px solid #ca373c50;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 0.8rem;
  font-weight: 400;
  gap: 10px;
  justify-content: center;
  padding: 2px 10px;
}

.filerlist span.filtertag svg {
  fill: #ca373c;
  cursor: pointer;
  height: 0.8em;
  width: 0.8em;
}

.course-tiles .tilecard .groupcardesign,
.course-tiles .tilecard .usercardesign {
  aspect-ratio: 1 / 1;
  height: 100%;
  margin: 0;
  width: 100%;
}

.course-tiles {
  display: grid;
  gap: 1rem;
  --u_rg: 1.5rem;
  --u_cg: 1.5rem;
  grid-template-columns: repeat(var(--cols), 1fr);
  justify-items: stretch;
  justify-content: center;
  align-content: center;
  --cols: 1;
}

.groupcardesign,
.usercardesign {
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 200px;
  justify-content: center;
  margin-bottom: 15px;
  text-align: center;
  transition: 0.3s;
}

.groupcardesign .avatarimg img {
  height: 100%;
  object-fit: contain;
  padding: 4px;
  width: 100%;
}

.usergrouplist .groupcardesign .avatarimg img {
  height: 100%;
  object-fit: cover;
  padding: 4px;
  width: 100%;
}

.groupcardesign .groupname,
.usercardesign .groupname {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #333;
  display: -webkit-box;
  font-size: 0.9rem;
  margin: 0;
  overflow: hidden;
  padding: 10px 0;
  padding-top: 15px;
  text-overflow: ellipsis;
  white-space: normal;
  width: 85%;
}

.groupcardesign .groupusercount,
.usercardesign .groupusercount {
  display: flex;
  justify-content: center;
}

.groupcardesign p,
.usergrouplist .groupcardesign p {
  color: #66666e;
  font-size: 0.85rem;
  margin-bottom: 0;
  text-align: center;
}

.csvuploaddownload .uloadoanload {
  border: 1px solid #ca373c;
  margin-right: 0px;
  color: #333;
  font-weight: 500 !important;
  border-radius: 5px;
  font-size: 13px !important;
  transition: 0.3s;
  padding: 2px 0px 0px;
  min-width: 40px;
  /* height: 40px; */
  background-color: transparent;
  text-align: center;
  background-color: #fff;
}
.csvuploaddownload .uloadoanload:hover {
  background-color: #ca373c;
  border: 1px solid rgba(255, 255, 255, 0);
}
.csvuploaddownload .uloadoanload:hover svg {
  fill: white;
}
.csvuploaddownload .uloadoanload:hover span {
  color: #ca373c;
  background-color: #fff;
}
.csvuploaddownload .uloadoanload svg {
  width: 0.95em;
  height: 0.95em;
  padding: 1px 0px;
  fill: #ca373c;
}
.csvuploaddownload .uloadoanload span {
  font-size: 10px;
  display: block;
  background-color: #ca373c;
  color: #fff;
  padding: 3px 0px;
  line-height: 1;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.csvuploadefs {
  font-size: 13px;
}

.dropzone {
  background-color: #f8f8f8;
  border: 2px dashed #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 5.5rem;
  line-height: 5.5rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 5.5rem;
}

.csvuploaddrr .dropzone {
  border-radius: 5px;
  height: 190px;
  line-height: 165px;
  position: relative;
  width: 100%;
}

.dropzone p {
  color: #333;
  font-size: 16px;
}

.csvuploaddrr .dropzone p {
  bottom: -30px;
  left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 100%;
}

p.Mui-error,
p.errormessage,
p.successmessage {
  font-size: 0.7rem !important;
  margin: 0 !important;
  padding: 0 !important;
}

p.Mui-error,
p.errormessage {
  color: #ff4842 !important;
}

.download-text {
  color: #ca373c;
  font-weight: 500;
}

.csvuploaddrr .csvfiledatalist {
  max-height: 400px;
  overflow-y: auto;
  height: 100%;
  min-height: 10rem;
}
.csvuploaddrr .csvfiledatalist .table thead tr th {
  background-color: #f3f3f3;
}
.csvuploaddrr .csvfiledatalist .table tbody tr td {
  border: none;
}
.csvuploaddrr .csvfiledatalist p {
  color: #66666e;
  margin-bottom: 10px;
}
.csvuploaddrr table tr th {
  font-size: 14px;
  padding: 0.5rem 0.2rem;
}
.csvuploaddrr table tr td {
  font-size: 14px;
  padding: 0.5rem 0.2rem;
}
.csvuploaddrr table tr td svg {
  width: 0.7em;
  height: 0.7em;
}
.csvuploaddrr .cancelcsv {
  cursor: pointer;
}

.csvuploaddrr .avatartc {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: #66666e;
  margin-right: 5px;
}

.avcharactr {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #ccc;
  font-size: 8px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-check.radio {
  align-items: center;
  display: flex;
  margin: 0;
  gap: 10px;
}

.radio.form-check .form-check-input {
  border-color: #ca373c;
  cursor: pointer;
  margin: 0 !important;
}

.form-group label,
.standardinput label {
  color: #000;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.radio input {
  padding: 0;
}

.radio.form-check .form-check-input:checked {
  background-color: #ca373c;
}

.form-check-input {
  width: 1em;
  height: 1em;
}

.radio.form-check .form-check-input:focus,
.radio.form-check .form-check-input:focus-visible,
.radio.form-check .form-check-input:focus-within {
  box-shadow: 0 0 0 0.25rem #ca373c38;
  background-color: #ca373c;
}

.filerlist span {
  font-size: 14px;
  font-weight: 500;
}

.filerlist span.filtertag {
  background-color: #fff;
}

.filerlist span.filtertag,
.filerlist span.filtertag.clear {
  align-items: center;
  border: 1px solid #ca373c50;
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  gap: 10px;
  justify-content: center;
  padding: 2px 10px;
}

.filerlist span.filtertag.clear {
  background-color: #ca373c;
  color: #fff;
}

.react-loading-skeleton {
  line-height: inherit !important;
}

table .form-check.radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.table a,
.link {
  color: #0044cc !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.details-here p {
  font-weight: 600;
  font-size: 1.15rem;
  margin: 0;
}

.details-here {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.55rem 1rem;
  width: 100%;
  background-color: #fff;
  border-bottom: 2px solid #ccc;
  background-color: #fff;
  font-size: 0.9rem;
}

.delete-btn {
  background-color: #ca373c30;
  color: #ca373c;
  font-weight: 500;
  padding: 0 1rem;
  height: 35px;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  transition: 300ms all;
}

.delete-btn:hover {
  color: #fff;
  background-color: #ca373c;
}

.delete-btn.disable {
  opacity: 0.5;
}

.delete-btn.disable:hover {
  background-color: #ca373c30;
  color: #ca373c;
}

.popupheader.delete {
  background-color: #fff;
  border-bottom: none;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  text-align: center;
}

.footerbtn button.cancel {
  background: transparent;
  color: #333;
}

.footerbtn button.cancel,
.footerbtn button.save {
  border: 1px solid #cad1da;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  margin: 5px;
  min-width: 6rem;
}

.popupheading.delete {
  opacity: 70%;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}

.total-lane {
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.7rem;
  text-align: right;
  margin: 0;
  background-color: #fff;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-lane a {
  color: #0044cc !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.no-chart-data {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-chart-data h5 {
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
  z-index: 2;
  position: relative;
  margin: 0;
  padding-top: 1rem;
}

.no-chart-data img {
  position: absolute;
  z-index: 1;
  bottom: -10px;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00000020;
}

.select-options {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2rem;
}

.p-multiselect-panel .p-multiselect-items,
.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.prime-select .p-multiselect-label,
.prime-select .p-dropdown-label {
  padding: 0.53rem;
  font-size: 14px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ca373c;
  background: #ca373c20;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #ca373c;
  background: #ca373c;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #ca373c50;
}

.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: #ca373c50;
  box-shadow: 0 0 0 0.2rem #ca373c00;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 14px;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-inputtext:enabled:hover {
  border-color: #ca373c;
}

.p-inputtext:enabled:focus {
  border-color: #ca373c;
  box-shadow: 0 0 0 0.2rem #ca373c40;
}

.p-inputtext {
  padding: 0.5rem 0.5rem;
  font-size: 0.85rem;
}

.prime-select {
  position: relative;
}

.dselect {
  width: 25px;
  height: 25px;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 20px;
  cursor: pointer;
  /* border: 1px solid #33333350; */
  background-color: #00000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
}

.dselect:hover {
  background-color: #00000010;
}

.prime-select .p-multiselect-label {
  padding-right: 1rem;
}

.prime-select .p-multiselect-label {
  width: 12rem;
}

.p-multiselect .p-multiselect-trigger {
  width: 2rem;
}

.image-here {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.image-here img {
  max-height: 80vh;
  width: 100%;
  object-fit: contain;
}

.snapShots .modal-content {
  background-color: transparent !important;
  border: none;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.7 !important;
}

.action-btns {
  color: #fff;
  background-color: #ca373c;
  /* border: 1px solid #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  backdrop-filter: (5px) blur;
  border-radius: 5px;
  padding: 0.8rem 3rem;
  cursor: pointer;
  position: absolute;
  font-size: 1.05rem;
  bottom: 5%;
}

.action-btns svg {
  width: 1.35rem;
  height: 1.35rem;
}

.load-btn svg {
  width: 1.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.load-btn circle {
  fill: none;
  stroke: #0044cc;
  stroke-width: 5;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.filteroption span {
  align-items: center;
  background: #ca373c;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 5px;
  justify-content: center;
  padding: 10px;
  width: -webkit-max-content;
  width: max-content;
}

.daterangepickerf {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  right: 1.3rem;
  top: 0;
  z-index: 10;
}

.rdrDateRangePickerWrapper {
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.date-range-btns {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: end;
  margin-left: auto;
  margin-top: 0.5rem;
  width: 100%;
}

.date-range-btns .DefaultRedBtn.reset {
  background-color: initial;
  border-color: #33333320;
  color: #333;
  font-weight: 500;
}

.rdrDateDisplay {
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplay, .rdrDay, .rdrDayEndPreview, .rdrDayInPreview, .rdrDayNumber, .rdrDayStartPreview, .rdrEndEdge, .rdrInRange, .rdrStartEdge, .rdrStaticRangeLabel {
  color: #ca373c !important;
}

.rdrDateDisplayItem {
  color: inherit;
  flex: 1 1;
  text-align: center;
  width: 0;
}

.rdrDayToday .rdrDayNumber span:after{
  background: #ca373c !important;
}